import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { formatDate, isNotNullOrEmpty } from "utils";
import { Typography, Grid, Divider } from "@material-ui/core";
import {
	SectionCard,
	AttachmentsSectionCard,
	HazardSubstanceListItem,
	ValueText,
	Lookup,
} from "frame/components";
import { useSelector, useDispatch } from "react-redux";
import { ajax } from "rxjs/ajax";
import { authHeaders, getBaseUrl } from "frame";
import { onHazardListRequest, onHazardousSubstanceListRequest } from "features/health-and-safety/actions";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		padding: theme.spacing(2, 0, 1, 0),
	},
	listItem: {
		padding: theme.spacing(1, 0),
		display: "flex",
		flexDirection: "row",
	},
	label: {
		minWidth: 250,
		marginTop: theme.spacing(1.5),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	hazardousSubstances,
	attachments,
	recipients,
	recipientsOther,
	hazardNotes,
	notes,
	organisationId,
}) => ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	substances: hazardousSubstances,
	hazardNotes,
	notes,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const ViewInductionMinutesForm = ({
	id,
	onChange = () => {},
	promptUnreadGuidelines,
	setReadAllGuidelines,
	guidelinesBlockRef,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [values, setValues] = useState(null);
	const [error, setError] = useState(null);

	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);
	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);
	const currentOrganisation = useSelector(
		({ organisations }) => organisations.currentOrganisation
	);

	const [readHazardGuidelines, setReadHazardGuidelines] = useState([]);
	const [readSubstanceGuidelines, setReadSubstanceGuidelines] = useState([]);

	// Fetch hazards and substances
	useEffect(() => {
		if (currentOrganisation) {
			dispatch(onHazardListRequest());
			dispatch(onHazardousSubstanceListRequest());
		}
	}, [dispatch, currentOrganisation]);

	// Fetch induction data
	useEffect(() => {
		if (!id || !currentOrganisation) {
			return;
		}

		setIsLoading(true);
		setError(null);

		const url = `${getBaseUrl()}/api/${currentOrganisation}/induction-minutes/${id}`;
		console.log("Fetching induction data from:", url);

		ajax.get(
			url,
			{
				...authHeaders(),
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		).subscribe({
			next: (response) => {
				console.log("Received induction data:", response.response);
				const hydratedData = hydrate(response.response);
				setValues(hydratedData);
				onChange(hydratedData);

				// Initialize read status arrays with false values
				const hazardCount = hydratedData.hazards ? hydratedData.hazards.length : 0;
				const substanceCount = hydratedData.substances ? hydratedData.substances.length : 0;
				
				console.log('Initializing read status arrays:', {
					hazardCount,
					substanceCount
				});

				setReadHazardGuidelines(new Array(hazardCount).fill(false));
				setReadSubstanceGuidelines(new Array(substanceCount).fill(false));
				
				// Set initial read status to false
				setReadAllGuidelines(false);
				setIsLoading(false);
			},
			error: (error) => {
				console.error("Error fetching induction data:", error);
				setError("Failed to load induction data. Please try again.");
				setIsLoading(false);
			}
		});
	}, [id, currentOrganisation, onChange]);

	const validateIfReadAllGuidelines = () => {
		const allHazardsRead = values?.hazards
			? readHazardGuidelines.length === values.hazards.length && readHazardGuidelines.every(isRead => isRead)
			: true;
		const allSubstancesRead = values?.substances
			? readSubstanceGuidelines.length === values.substances.length && readSubstanceGuidelines.every(isRead => isRead)
			: true;

		console.log('Validating guidelines:', {
			hazards: values?.hazards,
			substances: values?.substances,
			readHazardGuidelines,
			readSubstanceGuidelines,
			allHazardsRead,
			allSubstancesRead
		});

		setReadAllGuidelines(allHazardsRead && allSubstancesRead);
	};

	const onReadHazardGuidelines = (index) => () => {
		console.log('Reading hazard guideline:', index);
		setReadHazardGuidelines(prev => {
			const newState = [...prev];
			newState[index] = true;
			// Validate immediately after state update
			setTimeout(() => {
				const allHazardsRead = values?.hazards
					? newState.length === values.hazards.length && newState.every(isRead => isRead)
					: true;
				const allSubstancesRead = values?.substances
					? readSubstanceGuidelines.length === values.substances.length && readSubstanceGuidelines.every(isRead => isRead)
					: true;
				setReadAllGuidelines(allHazardsRead && allSubstancesRead);
			}, 0);
			return newState;
		});
	};

	const onReadSubstanceGuidelines = (index) => () => {
		console.log('Reading substance guideline:', index);
		setReadSubstanceGuidelines(prev => {
			const newState = [...prev];
			newState[index] = true;
			// Validate immediately after state update
			setTimeout(() => {
				const allHazardsRead = values?.hazards
					? readHazardGuidelines.length === values.hazards.length && readHazardGuidelines.every(isRead => isRead)
					: true;
				const allSubstancesRead = values?.substances
					? newState.length === values.substances.length && newState.every(isRead => isRead)
					: true;
				setReadAllGuidelines(allHazardsRead && allSubstancesRead);
			}, 0);
			return newState;
		});
	};

	if (isLoading) {
		return (
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<Typography>Loading induction data...</Typography>
				</Grid>
			</Grid>
		);
	}

	if (error) {
		return (
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<Typography color="error">{error}</Typography>
				</Grid>
			</Grid>
		);
	}

	if (!values) {
		return (
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<Typography>No induction data found</Typography>
				</Grid>
			</Grid>
		);
	}

	return (
		<ValidatorForm
			className={classes.root}
			autoComplete="off"
			noValidate
			onSubmit={(e) => e.preventDefault()}
		>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<SectionCard>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ValueText
									label="Site"
									value={
										<Lookup
											id={values.siteId}
											resource="sites"
											attribute="siteName"
										/>
									}
									options={{ bold: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<ValueText
									label="Date"
									value={formatDate(values.createdAt)}
									options={{ bold: true }}
								/>
							</Grid>
						</Grid>
					</SectionCard>

					<SectionCard title="People">
						<Grid container spacing={2}>
							{isNotNullOrEmpty(values.people) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Staff People:</strong>
									</Typography>

									{values.people.map(
										({ name, role }, index) => (
											<div
												key={`view-induction-staff-people-${index}`}
											>
												<div
													className={
														classes.listItem
													}
												>
													<Typography
														component="span"
														variant="body1"
														style={{
															justifyContent:
																"flex-start",
														}}
													>
														{name}
													</Typography>
													<span
														style={{ flex: 1 }}
													></span>
													<Typography
														component="span"
														variant="body1"
														style={{
															justifyContent:
																"flex-end",
														}}
													>
														{role}
													</Typography>
												</div>
												<Divider />
											</div>
										)
									)}
								</Grid>
							)}

							{isNotNullOrEmpty(values.otherPeople) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Other People:</strong>
									</Typography>
									{values.otherPeople.map((name, index) => (
										<div
											key={`view-induction-other-people-${index}`}
										>
											<div
												className={classes.listItem}
											>
												<Typography
													component="span"
													variant="body1"
													style={{
														justifyContent:
															"flex-start",
													}}
												>
													{name}
												</Typography>
												<span
													style={{ flex: 1 }}
												></span>
											</div>
											<Divider />
										</div>
									))}
								</Grid>
							)}

							{!isNotNullOrEmpty(values.people) &&
								!isNotNullOrEmpty(values.otherPeople) &&
								"-"}
						</Grid>
					</SectionCard>

					<div ref={guidelinesBlockRef} />
					<SectionCard title="Hazards / Hazardous Substances">
						<Grid container spacing={2}>
							{isNotNullOrEmpty(values.hazards) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazards:</strong>
									</Typography>
									<div>
										{hazardList
											? values.hazards.map(
													(hazardId, index) => (
														<React.Fragment key={hazardId}>
															<HazardSubstanceListItem
																isHazard
																id={hazardId}
																hazardList={hazardList}
																onReadGuidelines={onReadHazardGuidelines(index)}
																promptUnread={promptUnreadGuidelines}
															/>
															<Divider />
														</React.Fragment>
													)
											  )
											: "loading..."}
									</div>
								</Grid>
							)}

							{isNotNullOrEmpty(values.substances) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazardous Substances:</strong>
									</Typography>
									<div>
										{hazardousSubstanceList
											? values.substances.map(
													(substanceId, index) => (
														<React.Fragment key={substanceId}>
															<HazardSubstanceListItem
																id={substanceId}
																hazardousSubstanceList={hazardousSubstanceList}
																onReadGuidelines={onReadSubstanceGuidelines(index)}
																promptUnread={promptUnreadGuidelines}
															/>
															<Divider />
														</React.Fragment>
													)
											  )
											: "loading..."}
									</div>
								</Grid>
							)}
						</Grid>
						{!isNotNullOrEmpty(values.hazards) &&
							!isNotNullOrEmpty(values.substances) &&
							"-"}
					</SectionCard>

					<AttachmentsSectionCard values={values} />

					<SectionCard title="Notes">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									align="left"
									gutterBottom
									variant="body1"
								>
									{values.notes || "-"}
								</Typography>
							</Grid>
						</Grid>
					</SectionCard>
				</Grid>
			</Grid>
		</ValidatorForm>
	);
};

ViewInductionMinutesForm.propTypes = {
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	promptUnreadGuidelines: PropTypes.bool,
	setReadAllGuidelines: PropTypes.func.isRequired,
	guidelinesBlockRef: PropTypes.object.isRequired,
};

export default ViewInductionMinutesForm;
