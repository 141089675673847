import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
	CircularProgress,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import {
	Page,
	PageHeader,
	PageContent,
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import { ViewInductionMinutesForm } from "features/health-safety-forms";
import { useRouter } from "utils";

const ColorCircularProgress = withStyles((theme) => ({
	root: {
		color: theme.palette.brand,
		marginRight: theme.spacing(2),
	},
}))(CircularProgress);

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 0),
	},
	body: {
		marginTop: theme.spacing(-3),
	},
	actions: {
		paddingLeft: theme.spacing(3),
		paddingBottom: theme.spacing(8),
	},
	loader: {
		color: "#6798e5",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
}));

const InductionMinutesConfirmAttendance = ({ onConfirm, organisationName }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const guidelinesBlockRef = useRef();

	const [id, setId] = useState(null);
	const [inductionFound, setInductionFound] = useState(false);
	const [readAllGuidelines, setReadAllGuidelines] = useState(false);
	const [promptUnreadGuidelines, setPromptUnreadGuidelines] = useState(false);

	useEffect(() => {
		if (!inductionFound && organisationName !== "") {
			const inductionId = window.location.href
				.split("induction-minutes/")
				.pop()
				.split("/confirm-attendance")[0];
			
			if (inductionId) {
				console.log('Found induction ID:', inductionId);
				setId(inductionId);
				setInductionFound(true);
			}
		}
	}, [inductionFound, organisationName]);

	const onCancel = () => {
		history.push("/");
	};

	const onSubmit = () => {
		console.log('Attempting to confirm attendance. All guidelines read:', readAllGuidelines);
		if (readAllGuidelines) {
			onConfirm(id);
		} else {
			setPromptUnreadGuidelines(true);
			console.log('Not all guidelines have been read. Scrolling to guidelines section.');
			guidelinesBlockRef.current &&
				guidelinesBlockRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
		}
	};

	return (
		<Page className={classes.root} title="Induction Meeting">
			<PageHeader
				breadcrumbs="Induction Minutes"
				title={"Induction Meeting - Confirm Attendance"}
			/>
			<div className={classes.body}>
				<PageContent pad>
					{id && organisationName !== "" ? (
						<ViewInductionMinutesForm
							id={id}
							promptUnreadGuidelines={promptUnreadGuidelines}
							setReadAllGuidelines={setReadAllGuidelines}
							guidelinesBlockRef={guidelinesBlockRef}
						/>
					) : (
						<ListItem>
							<ListItemIcon>
								<ColorCircularProgress
									size={30}
									thickness={5}
								/>
							</ListItemIcon>
							<ListItemText primary="loading" />
						</ListItem>
					)}
				</PageContent>
			</div>

			{id && (
				<FormActions className={classes.actions}>
					<FormSubmitButton
						data-cy={`confirm-btn`}
						onClick={onSubmit}
						disabled={!readAllGuidelines}
					>
						{readAllGuidelines ? "Confirm Attendance" : "Please Read All Guidelines"}
					</FormSubmitButton>
					<FormButton onClick={onCancel}>Cancel</FormButton>
				</FormActions>
			)}
		</Page>
	);
};

InductionMinutesConfirmAttendance.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	organisationName: PropTypes.string.isRequired,
};

export default InductionMinutesConfirmAttendance;
