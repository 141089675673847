import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { isImageFile, isNotNullOrEmpty } from "utils";
import { LightboxWrapper } from "frame/components";
import { DBService, getTableName } from "frame/indexedDBService";
import { getToken } from "frame/access-token";

const useStyles = makeStyles((theme) => ({
	downloadLink: {},
}));

/*
  Attachments storage/retrieval process:
  all forms(except photo) posts dataurl (base64 string) to api,
  photo form works different (for offline more) doesnt post directly to api on form submit,
  it uses a queue effect, rehydrates dataUrl from indexDb and makes post request in queue effect (keeps trying if offline)

  once posted, attachments are returned as "api file url" (eg. http:localhost:5100/api/{org_id}/file/{type}/{file_id} ) - see file controller
  on list view, it will first try to look for base64 string in indexdb and load files with that
  else if not found, it will fail back to using the "api file url", appending access_token to it inorder to work. -- see retrieveAttachments() 
*/
const AttachmentListing = ({ attachments, hasNetwork }) => {
	const classes = useStyles();
	const [fileList, setFileList] = useState([]);
	const db = new DBService();

	useEffect(() => {
		if (isNotNullOrEmpty(attachments)) {
			retrieveAttachments(attachments);
		}
		// eslint-disable-next-line
	}, [attachments]);

	useEffect(() => {
		if (
			!hasNetwork ||
			(isNotNullOrEmpty(attachments) && attachments[0].dataURL === "")
		) {
			// retreive image from indexdb if offline
			retrieveAttachments(attachments);
		}
		// eslint-disable-next-line
	}, [hasNetwork]);

	// Function to check if a URL is a blob storage URL
	const isBlobStorageUrl = (url) => {
		if (!url) return false;
		// Check common patterns for blob storage URLs (Azure blob storage, Cloudinary, etc.)
		return url.includes('.blob.core.windows.net') || 
			  url.includes('fileBlobDomain') || 
			  url.includes('cloudinary') ||
			  url.includes('blob.');
	};

	// retreive base64 dataUrl from indexdb, falls back to api file url if not found in indexdb
	const retrieveAttachments = async (attachmentList) => {
		let files = [];

		for (let i in attachmentList) {
			// First check if the original attachment's dataURL is already a blob storage URL
			// This is how JobController sends it - both dataURL and cloudinaryURL are the blob URL
			if (attachmentList[i].dataURL && isBlobStorageUrl(attachmentList[i].dataURL)) {
				// If the dataURL is already a blob URL, use it directly without checking IndexedDB
				files.push({...attachmentList[i]});
				continue;
			}

			// If not a blob URL in dataURL, check IndexedDB or use original
			let attachment = await db.get(getTableName(), attachmentList[i].id);
			let fileToAdd = attachment ? attachment : attachmentList[i]; // falls back if not found in indexdb

			// Handle case sensitivity issues - backend may send HasBlob/CloudinaryURL (uppercase) or hasBlob/cloudinaryURL (lowercase)
			const hasBlob = fileToAdd.hasBlob || fileToAdd.HasBlob;
			const cloudinaryURL = fileToAdd.cloudinaryURL || fileToAdd.CloudinaryURL;

			// If the file has a blob in storage or the DataURL looks like a blob storage URL, prioritize cloudinaryURL
			if ((hasBlob && cloudinaryURL) || (cloudinaryURL && isBlobStorageUrl(cloudinaryURL))) {
				fileToAdd.dataURL = cloudinaryURL;
			}
			// If DataURL looks like a blob storage URL, use it directly
			else if (fileToAdd.dataURL && isBlobStorageUrl(fileToAdd.dataURL)) {
				// Keep the existing dataURL
			}
			// Otherwise, append access token to data url if using api file url
			else if (/^http/i.test(fileToAdd.dataURL) && !fileToAdd.dataURL.includes("?access_token")) {
				fileToAdd.dataURL = addAuthHeader(fileToAdd.dataURL);
			}

			files.push(fileToAdd);
		}

		setFileList(files);
	};

	const imageAttachments = (images) => {
		let imageList =
			isNotNullOrEmpty(images) &&
			images.filter(
				(item) =>
					item &&
					item.fileExtension &&
					isImageFile(item.fileExtension)
			);

		return imageList && <LightboxWrapper attachments={imageList} />;
	};

	const addAuthHeader = (dataURL) => {
		const token = getToken();
		const link =
			/^http/i.test(dataURL) && dataURL.includes("/api/")
				? `${dataURL}?access_token=${token}`
				: dataURL;
		return link;
	};

	return (
		<>
			{isNotNullOrEmpty(fileList) &&
				fileList
					.filter((file) => file) //filter out null items
					.map(
						({ fileName, fileExtension, dataURL }, key) =>
							fileName &&
							fileExtension &&
							!isImageFile(fileExtension) && (
								<Typography key={key} variant="h5">
									<a
										href={dataURL || ""}
										download={fileName}
										className={classes.downloadLink}
									>
										{fileName}
									</a>
								</Typography>
							)
					)}
			{fileList && fileList.length === 0 && "-"}
			{fileList && fileList.length > 0 && imageAttachments(fileList)}
		</>
	);
};

AttachmentListing.propTypes = {
	attachments: PropTypes.array.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ queue }) => ({
	hasNetwork: queue.hasNetwork,
});

export default connect(mapStateToProps)(AttachmentListing);
